import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import React from "react";
import HomePage from "./Views/home"

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
            <HomePage/>
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;