import React, { useState, Component } from "react";
import axios from "axios";
import { ReactComponent as DoctorSvg } from "../Assets/images/doctor_svg.svg";

import { ReactComponent as LegalSvg } from "../Assets/images/legal_svg2.svg";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";

const doctorImage = require("../Assets/images/doctor.png");
const bannerImage = require("../Assets/images/banner_3.jpg");
const legalImage = require("../Assets/images/legal.png");
const houseImage = require("../Assets/images/house.png");
const scrollImage = require("../Assets/images/scrolldown.png");

const Alert = ({ show, type, message }) => {
  let className = `alert alert-${type} alert-dismissible fade show`;
  if (show) {
    return (
      <div
        className = {className}
        style={{ marginTop: 100 }}
        role="alert"
      >
        {message}
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  } else {
    return null;
  }
};

const EmailEnquiry = ({
  onChangeText,
  validateForm,
  sendMail,
  showAlert,
  emailValue,
  nameValue,
  messageValue,
  isLoading,
}) => {
  return (
    <div className="mailer-col col font-primary">
      <h1>Contact Us</h1>
      <br />
      <input
        onChange={(event) => onChangeText(event, "name")}
        className="mailer-col-input"
        type="text"
        placeholder="Your name"
        value={nameValue}
      />
      <br />
  
      
      <textarea
        onChange={(event) => onChangeText(event, "message")}
        placeholder="Your message"
        className="mailer-col-textarea"
        rows="10"
        cols="30"
        value={messageValue}
      ></textarea>
      <br />

      <button
        onClick={() => {
          if (validateForm()) sendMail();
        }}
        className="mailer-col-button"
      >
        {isLoading ? (
          <span class="spinner-border spinner-border-sm"></span>
        ) : (
          "SEND"
        )}
      </button>
      <Alert show={showAlert} type={"success"} message="Your enquiry is sent." />
    </div>
  );
};

const ContactUs = () => {
  return (
    <div className="col-md-8 contact-us-col font-primary">
      <h3>Call us</h3>
      <hr style={{ backgroundColor: "white" }}></hr>
      <b>
        <h5>Canada</h5>
      </b>
      <h6 className="font-secondary">
        <b>Phone:</b> +1 (437) 227-3711{" "}
      </h6>
      <h5>Australia</h5>
      <h6 className="font-secondary">
        <b>Phone:</b> +61 478 843 332{" "}
      </h6>
      <h5>India</h5>
      <h6 className="font-secondary">
        <b>Phone:</b>+91 8921953276{" "}
      </h6>
      <br />
      <h3>Email</h3>
      <hr style={{ backgroundColor: "white" }}></hr>
      <h6>enquiries@transverbsolutions.com</h6>
      <br />
      <h3>Address</h3>
      <hr style={{ backgroundColor: "white" }}></hr>
      <b>Canada:</b>
      <br />
      <p style={{ textAlign: "justify" }} className="font-secondary">
        3100 Harold Sheard Drive,Mississauga,Ontario L4T1V5, Canada
      </p>
      <b>Australia:</b>
      <br />
      <p style={{ textAlign: "justify" }} className="font-secondary">
        19 Redtail Street Chisholm, NSW 2322, Australia
      </p>
      <b>India:</b>
      <br />
      <p style={{ textAlign: "justify" }} className="font-secondary">
        10-B, Shwas Cyberhills, Kakkanad, Kerala, India
      </p>
    </div>
  );
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadComplete: false,
      homeActive: "navitem active",
      who: "",
      why: "",
      contact: "",
      email: "",
      name: "",
      message: "",
      showAlert: false,
      isLoading : false
    };
  }

  componentDidMount() {
    init("user_BkU7l26rPgk3L95pwJ56x");
    var interval = setInterval(() => {
      if (document.readyState === "complete") {
        clearInterval(interval);
        this.setState({ loadComplete: true });
      }
    }, 100);
  }

  sendMail = () => {
    let body = {
      message : this.state.message,
      name : this.state.name
    }
    this.setState({
      isLoading : true
    })
    axios.post(`https://transverb-be.herokuapp.com/mail`, body)
    .then((response) => {
      console.log("response")
      this.setState({
        showAlert: true,
        email: "",
        name: "",
        message: "",
        isLoading : false
      });
    })
    .catch((error) => {
      this.setState({isLoading : false});
      console.log("Mail error", error);
    });
     
  };

  validateForm = () => {
    let isValid = true;
    let validationItems = [
     
      ["name", "Name"],
      ["message", "Message"],
    ];
    validationItems.map((item, index) => {
      if (this.state[item[0]] == "") {
        alert(`${item[1]} is invalid`);
        isValid = false;
      }
    });
    return isValid;
  };

  preloader = () => {
    if (!this.state.loadComplete) {
      return (
        <div
          style={{
            backgroundColor: "black",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            zIndex: 1000,
            left: 0,
            right: 0,
          }}
        >
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
  };

  setActive = (type) => {
    this.setState({
      homeActive: "",
      who: "",
      why: "",
      contact: "",
      enquiries: "",
      [type]: "active",
    });
  };
  onChangeText = async (event, field) => {
    //console.log(event.target.value + " " + field);
    await this.setState({
      [field]: event.target.value,
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.preloader()}
        <div className="row">
          <div className="col">
            <div className="row">
              <nav class="col navbar navbar-expand-lg navbar-dark">
                <a class="navbar-brand" href="#">
                  <b>Transverb</b>
                </a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul class="navbar-nav">
                    <li class={this.state.homeActive}>
                      <a
                        onClick={() => this.setActive("homeActive")}
                        class="nav-link"
                        href="#"
                      >
                        Home <span class="sr-only">(current)</span>
                      </a>
                    </li>
                    <li class={this.state.who}>
                      <a
                        onClick={() => this.setActive("who")}
                        class="nav-link"
                        href="#who-we-are"
                      >
                        Who we are
                      </a>
                    </li>
                    <li class={this.state.why}>
                      <a
                        onClick={() => this.setActive("why")}
                        class="nav-link"
                        href="#why-choose-us"
                      >
                        Why us
                      </a>
                    </li>
                    <li class={this.state.contact}>
                      <a
                        onClick={() => this.setActive("contact")}
                        class="nav-link"
                        href="#contact"
                      >
                        Contact
                      </a>
                    </li>
                    <li class={this.state.enquiries}>
                      <a
                        onClick={() => this.setActive("enquiries")}
                        class="nav-link"
                        href="#enquiries"
                      >
                        Enquiries
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            style={{ backgroundImage: `url(${bannerImage})` }}
            className="col service-banner font-primary"
          >
            <div>
              <h1>Transcription services</h1>
              <h3 style={{ color: "white" }} id="who-we-are">
                Perfected.
              </h3>
              <img
                src={scrollImage}
                className="scroll-img"
                height="45"
                width="45"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col font-primary who-we-are-col">
            <h1>Who we are</h1>
          </div>
        </div>
        <div className="row">
          <div className="col font-primary who-we-are-desc">
            <p id="why-choose-us">
              Transverb Transcription is one of the leading medical and real
              estate transcription outsourcing companies in India. Transverb
              Transcription has been providing high quality medical
              transcription services online at affordable prices. We assist
              nursing homes, single doctor clinics, large group of physicians,
              hospitals and medical research centers to meet their transcription
              requirements. With a view to pitch accurate, error-free and prompt
              services. We have offices in Canada, Australia and India, So you
              can rely on us to provide exceptional services.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col font-primary who-we-are-col">
            <h1>Why choose us</h1>
          </div>
        </div>
        <div className="row">
          <div className="col font-primary who-we-are-desc">
            <p>
              Customer satisfaction by delivering services on time with
              exceptional quality is paramount to us and we will never
              compromise on these traits. We offer exceptional services at
              highly competitive rates without compromising on quality of
              deliverables. Since we are steadily growing and are on the
              look-out for new opportunities, we are flexible with the services
              we offer. This is definitely a win-win situation for both parties.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col font-primary services-heading">
            <h3>Our Services</h3>
          </div>
        </div>
        <div className="horizontal-line" />
        <div className="row">
          <div className="left-margin mr-auto col-md-4 font-primary service-type-heading">
            <h1>Medical Transcription</h1>
            <div className="horizontal-line-service" />
            <div>
              <p style={{ textAlign: "justify" }} className="font-secondary">
                Having been catering to the needs of healthcare organizations
                and physicians around the globe for over 21 years now, we
                understand the client's requirements thoroughly and provide the
                services accordingly. Our team comprises some of the most
                talented and skilled medical transcriptionists who can provide
                quality and error-free transcriptions within a quick time.
              </p>
            </div>
          </div>
          <div className="col service-image">
            <DoctorSvg fill="#00cdac" height="200" width="200" />
          </div>
        </div>
        <div className="row ">
          <div className="col service-image">
            <LegalSvg fill="#00cdac" height="200" width="200" />
          </div>
          <div className="right-margin ml-auto col-md-4 font-primary service-type-heading">
            <h1>Medico Legal Transcription</h1>
            <div className="horizontal-line-service" />
            <div>
              <p style={{ textAlign: "justify" }} className="font-secondary">
                Medico legal transcription files can be extremely technical, so
                our medico legal transcription team understands both medical and
                legal terminology. Every report is quality checked to ensure 99%
                accuracy and above.
              </p>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="left-margin mr-auto col-md-4 font-primary service-type-heading">
            <h1>Real Estate Transcription</h1>
            <div className="horizontal-line-service" />
            <div>
              <p style={{ textAlign: "justify" }} className="font-secondary">
                The real estate industry deals with recorded notes relating to
                construction sites and buildings, legal recordings of real
                estate deals, and general office work. Ranging from property
                registration details to back office documents and construction
                site notes, we can provide customized reports for any records
                you require.
              </p>
            </div>
          </div>
          <div className="col service-image">
            <img src={houseImage} height="120" width="120" />
          </div>
        </div>
        <div className="horizontal-line" id="contact" />

        <div className="row">
          <EmailEnquiry
            onChangeText={this.onChangeText}
            validateForm={this.validateForm}
            sendMail={this.sendMail}
            showAlert={this.state.showAlert}
            emailValue={this.state.email}
            messageValue={this.state.message}
            nameValue={this.state.name}
            isLoading={this.state.isLoading}
          />
        </div>
        <div className="row">
          <div className="col font-primary contact-us-heading">
            <div className="horizontal-line" id="enquiries" />
            <h1>For Enquiries</h1>
          </div>
        </div>

        <div className="row">
          <ContactUs />
        </div>
      </div>
    );
  }
}

export default HomePage;
